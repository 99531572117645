import { fontWeight } from "@mui/system";
import React, {useState, useEffect} from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive';

function SoftwareEngineer() {
  const [hoveredLink, setHoveredLink] = useState(null);
  const [previewData, setPreviewData] = useState(null);
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  const page_header = {
    fontSize: '18px',
    paddingTop: '20vh',
    fontFamily: 'Deja Vu',
    textAlign: 'left',
    fontWeight: 'bold'
  }

  const blurb_style = {
    fontSize: '16px',
    fontFamily: 'Deja Vu',
    textAlign: 'left',
    marginBottom: '20px',
  }

  const previewStyle = {
    // border: '1px solid #ccc',
    // backgroundColor: '#fff',
    width: '500px',
    height: '500px',
    marginTop: '30vh',
    paddingLeft: '20px'
  };
  
  const imageStyle = {
    maxWidth: '100%',
    height: 'auto',
  };

  const previewText = {
    fontSize: '16px',
    fontFamily: 'Deja Vu',
    textAlign: 'left',
  }

  const previewSubtitle = {
    fontSize: '14px',
    fontFamily: 'Deja Vu',
    textAlign: 'left',
    color: 'gray',
  }

  const previewTitle = {
    fontSize: '18px',
    fontWeight: 'bold',
    fontFamily: 'Deja Vu',
    textAlign: 'left',
    margin: 0,
  }

  async function fetchPreviewData(text) {
    try {
        const response = await fetch('/metadata.json');
        const jsonData = await response.json();
        console.log(jsonData)
        const result = jsonData.links.find(link => link.text.toLowerCase() === text.toLowerCase());
        setPreviewData(result);
        console.log(previewData);
      } catch (error) {
        console.error('Error fetching JSON data:', error);
        return `No link found for text: ${text}`;
    }
  }

  const handleMouseEnter = (url) => {
    setHoveredLink(url);
    fetchPreviewData(url);
  };

  const handleMouseLeave = () => {
    setHoveredLink(null);
    setPreviewData(null);
  };

  return (
    <section>
        <Container className="home-section" id="software-engineer">
            <Col md={4} style={{'paddingLeft': '50px'}}>
                <p style={page_header}>Software Engineer</p>
                <div style={blurb_style}>
                    <a href="https://www.linkedin.com/company/lab126"
                        onMouseEnter={() => handleMouseEnter('Amazon Lab126')}
                        onMouseLeave={handleMouseLeave}
                        target="_"
                    > Amazon Lab126
                    </a><br></br>
                    <a href="/ot-profiles"
                        onMouseEnter={() => handleMouseEnter('OfficeTogether (acq. Envoy)')}
                        onMouseLeave={handleMouseLeave}
                        target="_"
                    >OfficeTogether (acq. Envoy)</a><br></br>
                    <a href="https://www.jncsolutions.com/"
                        onMouseEnter={() => handleMouseEnter('JNC Solutions Inc.')}
                        onMouseLeave={handleMouseLeave}
                        target="_"
                    >JNC Solutions Inc.</a>
                </div>
                <div style={blurb_style}>
                    <a href="https://theharvardadvocate.com/"
                        onMouseEnter={() => handleMouseEnter('The Harvard Advocate')}
                        onMouseLeave={handleMouseLeave}
                        target="_"
                    >The Harvard Advocate</a><br></br>
                    <a href="https://touchingpitch.com/index.html"
                        onMouseEnter={() => handleMouseEnter('Radcliffe Research Program')}
                        onMouseLeave={handleMouseLeave}
                        target="_"
                    >Radcliffe Research Program</a><br></br>
                    <a href="https://hcs.harvard.edu/about/"
                        onMouseEnter={() => handleMouseEnter('Harvard Computer Society')}
                        onMouseLeave={handleMouseLeave}
                        target="_"
                    >Harvard Computer Society</a><br></br>
                    <a href="https://datamatch.me/"
                        onMouseEnter={() => handleMouseEnter('datamatch')}
                        onMouseLeave={handleMouseLeave}
                        target="_"
                    >Datamatch</a>
                </div>
                <div style={blurb_style}>
                    <a href="softwareengineer/wave"
                        onMouseEnter={() => handleMouseEnter('Wave')}
                        onMouseLeave={handleMouseLeave}
                    >Wave</a><br></br>
                    <a
                        onMouseEnter={() => handleMouseEnter('Punch')}
                        onMouseLeave={handleMouseLeave}
                    >Punch</a><br></br>
                    <a href="https://devpost.com/software/destifi"
                        onMouseEnter={() => handleMouseEnter('Destifi')}
                        onMouseLeave={handleMouseLeave}
                        target="_"
                    >Destifi</a><br></br>
                    <a href="https://www.youtube.com/watch?v=WpwaKbNNmDw"
                        onMouseEnter={() => handleMouseEnter('Tokū')}
                        onMouseLeave={handleMouseLeave}
                        target="_"
                    >Tokū</a>
                </div>
                <div style={blurb_style}>
                    <a onMouseEnter={() => handleMouseEnter('LA Hacks 2022')}
                        onMouseLeave={handleMouseLeave}
                    >LA Hacks 2022</a><br></br>
                    <a onMouseEnter={() => handleMouseEnter('Congressional App Challenge 2020')}
                        onMouseLeave={handleMouseLeave}
                    >Congressional App Challenge 2020</a><br></br>
                    <a onMouseEnter={() => handleMouseEnter('HackMIT 2020')}
                        onMouseLeave={handleMouseLeave}
                    >HackMIT 2020</a>
                </div>
            </Col>
            <Col md={6}>
                {hoveredLink && previewData && (
                <div style={previewStyle}>
                    {/* {previewData.image && <img src={previewData.image} alt="Preview" style={imageStyle} />} */}
                    <h3 style={previewTitle}>{previewData.text}</h3>
                    <p style={previewSubtitle}>{previewData.position}</p>
                    <p style={previewText}>{previewData.description}</p>
                </div>
                )}
            </Col>
            
        </Container>
    </section>
    
  );
}

export default SoftwareEngineer;
