import React from 'react';
import { Container, Col } from "react-bootstrap";
import Typewriter from "typewriter-effect";

import profile_page from "./profile_page.png";
import profile_page_states from "./profile_page_states.png";
import coworker_mobile_profile from "./mobile_profile.png";
import mobile_profile from "./my_profile_mobile.png";
import documentation from "./documentation.png";
import coworker_profile_page from "./coworker_profile_page.png";
import profile_additional_details from "./profile_additional_details.mov";
import profile_demo from "./profile_demo.mov";

function OTProfiles() {
    return(
        <section>
            <Container fluid className="project-section">
            <Container className="project-content">
                <div style={{textAlign: "left"}}>
                    <h1 className='project-header'>OfficeTogether User Profiles</h1>
                    <p>The goal of this project was to increase product stickiness and gather metrics on user activity. In brainstorming project ideas, I recognized that there was no "space" on the website for users to interact with other users/coworkers and that the product lacked user engagement. I decided to dedicate my internship project to building out full-page user profiles, focusing on customizability, ease-of-use, and user analytics.</p>
                </div>
                <Col md={8}>
                    <h1 className='project-header'>Features</h1>
                    <img src={documentation} style={{maxHeight:"100%", maxWidth:"100%"}}/>
                </Col>
                <div style={{textAlign: "left"}}>
                    <h1 className='project-header'>Demos </h1>
                    <video width="800" src={profile_demo} controls autoPlay loop muted></video>
                    <p className='project-caption'>My Profile Demo</p>
                    <video width="800" src={profile_additional_details} controls autoPlay loop muted></video>
                    <p className='project-caption'>Edit Additional Details</p>
                </div>
                
                <h1 className='project-header'>Mockups</h1>
                <div className='grid-container'>
                    <div className='grid-item'>
                        <img src={profile_page_states} style={{maxHeight:"100%", maxWidth:"100%", objectFit: "cover"}}/>
                        <p className='project-caption'>My Profile Page</p>
                    </div>
                    <div className='grid-item'>
                        <img src={coworker_profile_page} style={{maxHeight:"100%", maxWidth:"100%", objectFit: "cover"}}/>
                        <p className='project-caption'>Coworker Profile Page</p>     
                    </div>
                    <div className='grid-item'>
                        <img src={mobile_profile} style={{maxHeight:"100%", maxWidth:"100%", objectFit: "cover"}}/>
                        <p className='project-caption'>My Profile Page (Mobile)</p>
                    </div>
                    <div className='grid-item'>
                        <img src={coworker_mobile_profile} style={{maxHeight:"100%", maxWidth:"100%", }}/>
                        <p className='project-caption'>Coworker Profile Page (Mobile)</p>
                    </div>
                </div>
            </Container>
        </Container>
        </section>
    );
}

export default OTProfiles;